export default Object.freeze({
  'coco-bongo-cancun': {
    name: 'Cocobongo Cancun',
    caption: 'Show & Disco',
    logo:
      'https://imgix.cosmicjs.com/f816bad0-6a28-11ef-b5ae-a594bb4a8e67-logo-coco-bongo-cancun.png',
    secondaryColor: '#643a43',
    color: '#c82121',
    video: {
      title: 'Coco Bongo Cancún',
      youtube: 'true',
      content:
        '<p>From Samba Carnival to acrobatic feats, Coco Bongo delivers unforgettable entertainment every night. Don\'t miss out on this iconic Cancún experience!</p>',
      id: 'nEL43Kp5yqo',
      videoUrl: 'https://www.youtube.com/watch?v=nEL43Kp5yqo',
    },
  },
});
